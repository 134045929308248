<template>
  <div>
     <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    
      <v-card >
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Edit Student Offer</v-toolbar-title>
          <v-spacer />
        </v-toolbar><br>
             <v-row justify="space-between">
                <v-col cols="12" sm="3" md="3">
                  <strong style="margin-left:20px;">{{offering}}</strong>
                </v-col>
                <v-col cols="12" sm="3" md="3">    
                 Current Placement Type : <strong> {{current_placementtype}}</strong>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                      <v-btn color="blue" dark @click="exceldialog = !exceldialog"
                            >Excel Import</v-btn
                          >
                        <v-dialog width="350" persistent v-model="exceldialog">
                          <v-card>
                            <v-card-title class="c-title">Excel import</v-card-title>
                            <v-card-text
                              ><br />
                              <v-row justify="end">
                                <a :href="url">
                                  <v-icon large color="blue" class="icon-quick" title="Download">
                                    mdi-file-excel
                                  </v-icon>
                                </a>
                              </v-row>
                              <v-file-input
                                v-model="excelfile"
                                label="Import Excel"
                              ></v-file-input>

                                <v-select :items="placementtype_list" placeholder="Select Placement Type"
                                v-model="placementtypeExcel" item-text="name" item-value="id"></v-select>
                            </v-card-text>
                            <v-card-actions>
                              <v-row class="pr-5" justify="end">
                                <v-btn @click="exceldialog = false" text>cancel</v-btn>
                                <v-btn @click="uploadexcel()" dark color="blue">Upload</v-btn>
                              </v-row>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                </v-col>
              </v-row> 
            <br>
                 <v-simple-table>
                      <thead>
                        <tr>
                          <th class="text-left">Sr. No</th>
                          <th class="text-left">Name</th>
                          <th class="text-left">PRN/GRNo</th>
                          <th class="text-left">Program</th>
                          <th class="text-left">Year</th>
                           <th class="text-left">Current Placement Type</th>
                          <th class="text-left" v-if="editrightpresent">
                            <v-row>
                              Selected
                              <input v-model="selectall" class="ml-2" style="width: 20px; height: 20px" type="checkbox" @click="checkAll(learner_list)"/>
                            </v-row>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in learner_list" :key="index">
                        <td style="color: green">{{index+1}}</td>
                          <td style="color: green">{{ item.learner_name }}</td>
                          <td style="color: green">{{ item.learner_grno }}</td>
                          <td style="color: green">{{ item.learner_program }}</td>
                          <td style="color: green">{{item.learner_year}}</td>
                          <td style="color: green">{{ item.learner_placamenttype }}</td>
                          <!-- <td style="color: green">{{ item.stud_year }}</td> -->
                          <td v-if="editrightpresent">
                            <input style="height: 40px; width: 30px" type="checkbox" v-model="item.isselected" @click="change(item)"/>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>      
             
        
      </v-card>
<br>
  <v-dialog v-model="errordialog" max-width="500px">
                         <v-data-table  id="exceltable"  :headers="headers1" :items="errorarray" class="elevation-1" :search="search">
                        <template v-slot:top>
                            <v-toolbar flat color="white">                              
                                <v-text-field v-model="search" append-icon="search" label="Search " single-line hide-details></v-text-field>
                                <v-spacer></v-spacer>
                                <v-btn  style="margin-right:50px" v-if="errorarray.length != 0"  @click="exportexcel()" color="success" dark class="mb-2">
                                    <v-icon dark>mdi-file-excel</v-icon>
                                </v-btn>
                            </v-toolbar>
                        </template>                       
                    </v-data-table>
                  </v-dialog>
       <v-card   class="elevation-12" v-if="name!=''">
    
      <v-row style="margin-left:10px">
          <v-col class="sm-md-7">
                   <v-select :items="placementtype_list" placeholder="Select Placement Type"
                   v-model="placementtype" item-text="name" item-value="id"></v-select>
                  </v-col>
          </v-col>
          <v-col class="sm-md-2">
                      <v-btn color="primary" @click="changeOfferType()" class="btn-title" dark>Update</v-btn>
          </v-col>
      </v-row>
      </v-card>


      <br>
      
    
  </div>
      

          
   
  </div>
</template>
<script>
import axios from "axios";
import {table2excel} from "../jquery.table2excel";
import readXlsxFile from "read-excel-file";
export default {
  data() {
    return {
      learner_list: [],
      scheduling_id:"",
      current_placementtype:"",
      placementtype_list:"",
      placementtype:"",
      offering:"",
      url:"",
      search:"",
      excelfile:null,
      snackbar_msg: "",
      placementtypeExcel:"",
    snackbar: false,
    exceldialog:false,
    color:"",
      headers: [
        {
          text: "Comapny Name",
          align: "start",
          sortable: true,
          value: "comapny_name",
        },
        { text: "Name", value: "learner_name" },
        { text: "Gr No", value: "learner_grno" },
        { text: "Program", value: "learner_program" },
        { text: "Year", value: "learner_year" },
        { text: "Current Placement type", value: "learner_placamenttype" },
        { text: "Change Offer", value: "check" },
      ],
      table_fields: {
      "Comapny Name": "comapny_name",
      "Registration Date": "registration_date",
      Name: "learner_name",
      "Gr No": "learner_grno",
      Program: "learner_program",
      Department: "learner_department",
      Year: "learner_year",
      // Questions:"questions"
      
    },
    
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    onload() {
      const data = {
        offering: this.scheduling_id,
      };
      axios
        .post("/OnCampusProcess/getPlacedStudentlist", data)
        .then((res) => {
          //console.log(res.data);
          if (res.data.msg == "200") {
            this.learner_list = res.data.comp_off_learner_list;
            this.offering = res.data.offering;
            this.current_placementtype= res.data.current_placementtype;
            this.placementtype_list=res.data.placementtype_list;
            this.editrightpresent = res.data.editrightpresent;
            this.url=res.data.url;
          } else {
            //error
            console.log("error");
            //console.log(res);
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          //close loader
          // this.get_ay_std_ft_loader = false;
        });
    },


showSnackbar(clr, msg) {
    this.snackbar = true;
    this.color = clr;
    this.snackbar_msg = msg;
  },

     changeOfferType() {
      if(this.placementtype == ""){
        this.showSnackbar("#b71c1c", "Please select Placement Type");
        return
      }
      var count =0;
       for(var i=0;i<this.learner_list.length;i++){
          if(this.learner_list[i].isselected == true){
              count++;
          }
          if(count > 0){
            break
          }
       }
       if(count == 0){
         this.showSnackbar("#b71c1c", "Please select at least one Student");
        return
       }
      const data = {
        offering: this.scheduling_id,
        learner_list : this.learner_list,
        placementtype:this.placementtype

      };
      axios
        .post("/OnCampusProcess/changeStudentOfferType", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.learner_list= res.data.comp_off_learner_list;
            this.showSnackbar("green", "Offer Updated Successfully"); // show snackbar       
          } else {
            //error
            console.log("error");
            //console.log(res);
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          //close loader
          // this.get_ay_std_ft_loader = false;
        });
    },

    uploadexcel() {
      if(this.placementtypeExcel == "") {
        this.showSnackbar("red", "Please select Placement Type");
      } else {
        var flag = 0;
        var j = 0;
        var studentlist = [];
        readXlsxFile(this.excelfile).then((rows) => {
          console.log(rows);
          var i;
          for (i = 1; i < rows.length; i++) {
            studentlist[i - 1] = rows[i][0];
          }

          var params = {
            studentlist: studentlist,
            offertype: this.placementtypeExcel,
            offering: this.scheduling_id,
          };
          axios.post("/OnCampusProcess/changeOfferbyExcel", params).then((res) => {
            if (res.data.msg == "200") {
             this.learner_list= res.data.comp_off_learner_list;
            this.showSnackbar("green", "Offer Updated Successfully");
            } else {
              this.showSnackbar("red", res.data.msg);
            }
          });
        });

        this.exceldialog = false;
      }
    },
      

     checkAll(hello) {
      for (var i = 0; i < this.learner_list.length; i++) {
        if (!this.selectall) {
          this.learner_list[i].isselected = true;
        } else {
          this.learner_list[i].isselected = false;
        }
      }
  },

     exportexcel() {    
        $("#exceltable").table2excel({  
          name: "Worksheet Name",
          filename: "Applications_Received", //do not include extension
          fileext: ".xls" // file extension
        });        
      },
  },
  mounted() {
    this.scheduling_id = this.$route.params.scheduling_id;
    //console.log("this.scheduling_id");
    //console.log(this.scheduling_id);
    this.onload();
  },
};
</script>
<style scoped>
.ec-title {
  background-image: linear-gradient(-100deg, #f4faba, #d64907);
  color: #fff;
}
.c-title {
  background-image: linear-gradient(-90deg, #befcf7, #03948d);
  color: #fff;
}
.tc-title {
  background-image: linear-gradient(-90deg, #fff, #057996);
  color: #fff;
  border-radius: 3px;
}
.btn-title {
  background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
  color: #fff;
}
.card-title {
  background-image: linear-gradient(-90deg, #8edffd, #034c70);
  color: #fff;
}
.lbl {
  color: #1705e6;
}
.c-title1 {
  background-image: linear-gradient(-90deg, #dbdbdb, #012944);
  color: #fff;
}
.link-icon {
  color: #fff;
}
.btn-title2 {
  background: linear-gradient(-90deg, #30cfd0 0%, #330867 100%);
}
.tableexcel{
display:none;
}

</style>